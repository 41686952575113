import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  CardTitle,
  Button,
  FormGroup,
  Form,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";
// import { db } from '../../firebase';
import ResumeCash from "./ResumCash";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";

import {
  setTicketsStats,
  fetchTicketsStats,
  setLogins,
} from "../../store/data/actions";
import {
  setCurrentCompanyById,
  addCompany,
  updateCompany,
} from "../../store/companies/actions";

import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  onSnapshot,
  where,
  query,
  collectionGroup,
  orderBy,
  writeBatch,
  setDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { firestore } from "../../Firebase/firebaseConfig";

import moment from "moment";
import "moment/locale/es";
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
// import { set } from "lodash";
import TicketsStats from "../Tickets/ticketstats";
import Ticket from "./lastTickets";
import Formulario from "./form";
import { useParams, useNavigate } from "react-router-dom";
import { set } from "lodash";

const API_KEY = "AIzaSyDYDLFn0MKiEELDLbripbCScd2bdpVJ3Wg";

const flags = ["gray", "yellow"];
const Dashboard = (props) => {
  const navigate = useNavigate();

  document.title = "Dashboard | TicketControl - Monitor";
  const [lastLogin, setLastLogin] = useState(null);
  const [hasTicket, setHasTicket] = useState(false);
  const [hasCompany, setHasCompany] = useState(false);
  const [days, setDays] = useState(0);
  const [date_install, setDateInstall] = useState("");
  const [isPro, setIsPro] = useState(false);
  const [modal, setModal] = useState(false);
  const [confirmId, setConfirmId] = useState("");
  const [error, setError] = useState("");
  const [deleted, setDeleted] = useState(false);
  const { id } = useParams();
  const [seguimiento, setSeguimiento] = useState(false);
  const [logoBase64, setLogoBase64] = useState(false);

  // ELIMINACION DE CUENTA
  const toggleModal = () => setModal(!modal);
  const handleDelete = () => {
    if (confirmId === company.id) {
      eliminarCuenta(company.id);
      toggleModal();
    } else {
      setError("El ID no coincide. Por favor, ingrese el ID correcto.");
    }
  };

  // const toggleSeguimiento = () => {
  //   setSeguimiento(!seguimiento);
  // };

  // useEffect(() => {
  //     changeSeguimiento();
  // }, [seguimiento]);

  useEffect(() => {
    setLastLogin(props.logins[0]);
  }, [props.logins]);

  useEffect(() => {
    const now = moment();
    const until = moment(props.licence.untildate);
    const days = until.diff(now, "days");
    setDays(days);
  }, [props.licence]);

  useEffect(() => {
    if (props.openedTickets.length > 0) {
      setHasTicket(true);
    } else {
      setHasTicket(false);
    }
  }, [props.openedTickets]);

  useEffect(() => {
    if (id) {
      // check if company exists in companies
      const company = props.companies.find((company) => company.id === id);
      if (company) {
        setHasCompany(true);
        props.setCurrentCompanyById(id);
        const is_pro =
          company.managerData && company.managerData?.isPro == true;
        setIsPro(is_pro);
        setSeguimiento(company.managerData?.sg == true ? true : false);
      } else {
        const documentRef = doc(firestore, "users", id);
        getDoc(documentRef)
          .then((docSnapshot) => {
            if (docSnapshot.exists()) {
              // Los datos del documento están disponibles en docSnapshot.data()
              const documento = docSnapshot.data();
              documento.company.id = id;
              documento.company.managerData = documento.managerData;
              props.addCompany(documento.company);
              setHasCompany(true);
              setIsPro(
                documento.managerData && documento.managerData?.isPro == true
              );
            } else {
              console.log("El documento no existe.");
            }
          })
          .catch((error) => {
            console.error("Error al obtener el documento:", error);
          });
      }
    } else {
      if (props.currentCompany) {
        setHasCompany(true);
        console.log("currentCompany", props.currentCompany);
        setIsPro(props.currentCompany.managerData?.isPro == true);
      } else {
        setHasCompany(false);
      }
    }

    setDateInstall(
      props.appConfig.localVariables?.dateInstall
        ? moment(props.appConfig.localVariables.dateInstall)
            .locale("es")
            .format("DD/MM/YYYY")
        : "No disponible"
    );
  }, [id, props.currentCompany]);

  useEffect(() => {
    if (props.appConfig.logo) {
      setLogoBase64("data:image/jpeg;base64," + props.appConfig.logo);
    } else {
      setLogoBase64(false);
    }
  }, [props.appConfig?.logo]);

  const { companies, company } = useSelector((state) => ({
    companies: state.companies.conmpanies,
    company: state.companies.currentCompany,
  }));

  const deleteCollection = async (collectionRef) => {
    const querySnapshot = await getDocs(collectionRef);
    const batch = writeBatch(firestore); // Usar writeBatch

    querySnapshot.forEach((docSnapshot) => {
      batch.delete(docSnapshot.ref);
    });

    await batch.commit();
  };

  const eliminarCuenta = async (userId) => {
    if (!userId) {
      console.error("El ID del usuario es requerido");
      return;
    }

    try {
      const userRef = doc(firestore, "users", userId);
      // Obtener las subcolecciones del documento
      const subCollections = [
        "auth",
        "cash",
        "cc",
        "cc_vehicles",
        "logins",
        "tickets",
      ]; // Añade aquí todas las subcolecciones conocidas

      // Eliminar subcolecciones
      for (const subCollection of subCollections) {
        const subCollectionRef = collection(userRef, subCollection);
        await deleteCollection(subCollectionRef);
      }

      // Finalmente, eliminar el documento principal
      await deleteDoc(userRef);
      setDeleted(true);
      console.log(
        `Documento con ID ${userId} y todas sus subcolecciones eliminados correctamente`
      );
    } catch (error) {
      console.error(
        "Error al eliminar el documento y sus subcolecciones: ",
        error
      );
    }
  };

  const changeSeguimiento = async () => {
    try {
      const userRef = doc(firestore, "users", company.id);
      console.log("userRef", userRef, company.id);
      const userDoc = await getDoc(userRef);
     
     
      if (userDoc.exists()) {
        await setDoc(
          userRef,
          {
            managerData: {
              ...userDoc.data().managerData,
              sg: !seguimiento,
            },
          },
          { merge: true }
        );
        console.log("Campo sg actualizado exitosamente");

        ///// REVISAR ESTO /////
        props.updateCompany({
          ...company,
          managerData: { ...company.managerData, sg: !seguimiento },
        });
        // setSeguimiento(!seguimiento);
        
      } else {
        console.log("El documento no existe");
      }
    } catch (error) {
      console.error("Error al actualizar el campo sg:", error);
    }
  };

  const changePro = async () => {
    try {
      const userRef = doc(firestore, "users", company.id);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        await setDoc(
          userRef,
          {
            managerData: {
              ...userDoc.data().managerData,
              isPro: !isPro,
            },
          },
          { merge: true }
        );
        setIsPro(!isPro);

        console.log("Campo managerData actualizado exitosamente");
        props.updateCompany({
          ...company,
          managerData: { ...company.managerData, isPro: !isPro },
        });
      } else {
        console.log("El documento no existe");
      }
    } catch (error) {
      console.error("Error al actualizar el campo managerData:", error);
    }
  };

  const place = company.address
    ? "https://www.google.com/maps/place/" +
      company.address.replace(" ", "+") +
      ", " +
      company.city.replace(" ", "+") +
      ", " +
      company.country.replace(" ", "+")
    : "";

  if (deleted) {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <h5>Se ha eliminado el registro con éxito</h5>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {/* {!hasCompany && <Container fluid={true}>No Hay Compañia</Container>} */}

        {
          <Container fluid={true}>
            {/* <Breadcrumbs title="TicketControl" breadcrumbItem="Dashboard" /> */}

            <Row>
              <Col lg={12}>
                <Row>
                  <Col
                    xxl={5}
                    xl={5}
                    lg={5}
                    md={4}
                    xs={12}
                    sm={12}
                    className="d-flex"
                  >
                    <Card className="flex-grow-1">
                      <CardBody>
                        <Row>
                          {logoBase64 && (
                            <Col md="auto">
                              {logoBase64 && (
                                <img
                                  src={logoBase64}
                                  alt="logo"
                                  style={{ width: "100px" }}
                                />
                              )}
                            </Col>
                          )}

                          <Col md="auto">
                            {/* <CardTitle>{company.name}</CardTitle> */}
                            <h2 className="text-primary">
                              <strong>
                                {company.name}{" "}
                                {isPro ? <Badge color={"info"}>PRO</Badge> : ""}
                              </strong>
                            </h2>
                            <h4 className="">{company.address}</h4>
                            <h4 className="">
                              {company.city} {company.country}
                            </h4>
                            {company.phone && (
                              <h4 className="">Teléfono: {company.phone}</h4>
                            )}
                            <Row className="align-items-center mt-3">
                              <Col md="auto">
                                <Formulario company={company} />
                              </Col>

                              <Col md="auto">
                                <a href={place} target="_map">
                                  VER EN GOOGLE MAPS
                                </a>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  {lastLogin && (
                    <Col
                      xxl={4}
                      xl={4}
                      lg={4}
                      md={4}
                      xs={6}
                      sm={6}
                      className="d-flex"
                    >
                      <Card className="flex-grow-1">
                        <CardBody>
                          <div className="card-title">
                            Cajero actual - Inicio de sesión{" "}
                            {moment(
                              lastLogin.created,
                              "YYYY-MM-DD HH:mm"
                            ).format("DD/MM HH:mm")}
                          </div>
                          <h2>{lastLogin.fullName}</h2>

                          {props.auths && (
                            <>
                              <div className="card-title">
                                Emails autorizados
                              </div>
                              {props.auths.map((auth) => (
                                <div key={auth.email}>{auth.email}</div>
                              ))}
                            </>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  )}

                  {hasTicket == true && (
                    <Col
                      xxl={3}
                      xl={3}
                      lg={3}
                      md={4}
                      xs={6}
                      sm={6}
                      className="d-flex"
                    >
                      <Ticket />
                    </Col>
                  )}
                </Row>

                {/* OBS */}
                {props.currentCompany?.obs && (
                  <Row>
                    <Col>
                      <Card className="flex-grow-1">
                        <CardBody>
                          <div className="card-title">Observación Interna</div>
                          <h3>{props.currentCompany?.obs}</h3>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}

                {/* ID KEY */}

                <Row>
                  <Col>
                    <Card className="flex-grow-1">
                      <CardBody>
                        <div className="card-title">KEY</div>
                        <h2>{company.id}</h2>
                        <h3>Versión DB: {props.appConfig.version_db}</h3>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col>
                    <Card className="flex-grow-1">
                      <CardBody>
                        <div className="card-title">LICENCIA</div>
                        <h4>
                          Valido hasta:{" "}
                          {moment(props.licence.untildate)
                            .subtract(1, "days")
                            .locale("es")
                            .format("DD/MM/YYYY")}{" "}
                          <small>(INCLUIDO)</small>
                        </h4>
                        <h4>Días restantes: {days}</h4>
                        <h5>Instalación: {date_install}</h5>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  {/* <h4>Tickets</h4> */}
                  <Col xl={12} lg={12} md={12} xs={12} sm={12}>
                    <TicketsStats />
                  </Col>
                  {props.resume_cash_items &&
                    props.resume_cash_items.length > 0 && (
                      <Col xl={12} lg={12} md={12} xs={12} sm={12}>
                        <ResumeCash
                          data={
                            props.resume_cash_items[
                              props.resume_cash_items.length - 1
                            ]
                          }
                        />
                      </Col>
                    )}
                </Row>
              </Col>

              <Col md={12}>
                <Card>
                  <CardBody>
                    <CardTitle>Acciones</CardTitle>

                    <Row align="center" justify="center" alignContent="center" alignItems="center">
                      <Col md={"auto"}>
                        <FormGroup switch>
                          <Input
                            type="switch"
                            role="switch"
                            valid={true}
                            checked={isPro}
                            onClick={(x) => {
                              changePro();
                            }}
                          />
                          <Label check>Cliente</Label>
                        </FormGroup>
                      </Col>

                      <Col md="auto">
                        <Button
                          color={"primary"}
                          className={"mx-2"}
                          onClick={toggleModal}
                        >
                          Eliminar Cuenta
                        </Button>
                      </Col>

                      <Col md="auto">
                        <Button
                          color={seguimiento ? "warning" : "default"}
                          className={"mx-2"}
                          onClick={() => {
                             changeSeguimiento();
                          }}
                        >
                          <i
                            className={"fa fas fa-flag"}
                            style={{
                              marginRight: "5px",
                              fontSize: 20,
                            }}
                          ></i>
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {props.location?.address && (
              <Row>
                <Col sm={12}>
                  <Card className="flex-grow-1">
                    <CardBody>
                      <CardTitle>Mapa</CardTitle>
                      <iframe
                        src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${props.location.latitude},${props.location.longitude}&zoom=13&maptype=roadmap`}
                        width="100%"
                        height="350"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                      ></iframe>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
        }
      </div>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Confirmar Eliminación</ModalHeader>
        <ModalBody>
          <p>
            Para confirmar la eliminación, por favor ingrese el ID del cliente.
            Esta acción es irreversible.
          </p>
          <FormGroup>
            <Label for="confirmId">ID del Cliente</Label>
            <Input
              id="confirmId"
              name="confirmId"
              placeholder="Ingrese el ID del cliente"
              type="text"
              value={confirmId}
              onChange={(e) => setConfirmId(e.target.value)}
            />
          </FormGroup>
          {error && <p className="text-danger">{error}</p>}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>
            Eliminar
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    customer_id: state.data.customer_id,
    currentCompany: state.companies.currentCompany,
    logins: state.data.logins,
    resume_cash_items: state.cash.resume_cash_items,
    openedTickets: state.data.openedTickets,
    companies: state.companies.companies,
    licence: state.data.licence,
    appConfig: state.data.appConfig,
    location: state.data.location,
    appConfig: state.data.appConfig,
    auths: state.data.auths,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCompanyById: (id) => dispatch(setCurrentCompanyById(id)),
    addCompany: (data) => dispatch(addCompany(data)),
    updateCompany: (data) => dispatch(updateCompany(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
