import { firestore } from "../../Firebase/firebaseStorage";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
  getDocs,
  getDoc,
  limit,
  startAfter,
  startAt,
} from "firebase/firestore";

import {
  FETCH_DATA_COMPANIES,
  SET_DATA_COMPANIES,
  SET_CURRENT_COMPANY,
  SET_CURRENT_COMPANY_BY_ID,
  ADD_COMPANY,
  UPDATE_COMPANY,
} from "./actionTypes";

const initialState = {
  companies: [],
  currentCompany: {},
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_COMPANIES:
      let current = localStorage.getItem("currentCompany");

      try {
        if (current) {
          const parsed = JSON.parse(current);
          current = action.payload.find((company) => company.id === parsed.id);
          if (!current) current = action.payload[0];
        } else {
          current = action.payload[0];
          localStorage.setItem("currentCompany", JSON.stringify(current));
        }
      } catch (e) {
        current = action.payload[0];
        localStorage.setItem("currentCompany", JSON.stringify(current));
      }
      return {
        ...state,
        companies: action.payload,
        currentCompany: current,
      };

    case ADD_COMPANY:
      localStorage.setItem("currentCompany", JSON.stringify(action.payload));
      console.log("xxxxxxxxxxxxxxxxx", [...state.companies, action.payload]);
      return {
        ...state,
        companies: [...state.companies, action.payload],
        currentCompany: action.payload,
      };

    case SET_CURRENT_COMPANY:
      localStorage.setItem("currentCompany", JSON.stringify(action.payload));
      return {
        ...state,
        currentCompany: action.payload,
      };

    case SET_CURRENT_COMPANY_BY_ID:
      const id = action.payload;
      let current__ = state.companies.find((company) => company.id === id);

      // si no hay current__
      if (!current__) {
        const usersRef = collection(firestore, "users");
        /// get data from users with id == id
        const q = query(usersRef, where("id", "==", id));
        getDocs(q).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            current__ = data;

            localStorage.setItem("currentCompany", JSON.stringify(current__));
            let x = {
              ...state,
              companies: [...state.companies, current__],
              currentCompany: current__,
            };

            return x;
          });
        });
      }

      localStorage.setItem("currentCompany", JSON.stringify(current__));
      let x = {
        ...state,
        companies: [...state.companies, current__],
        currentCompany: current__,
      };
      console.log("X", x);
      return x;

    case UPDATE_COMPANY:
      console.log("UPDATE_COMPANY", action.payload);
      let updated = state.companies.map((company) => {
        if (company.id === action.payload.id) {
          return { ...company, ...action.payload };
        }
        return company;
      });
      localStorage.setItem("currentCompany", JSON.stringify(action.payload));
      return {
        ...state,
        companies: updated,
        currentCompany: action.payload,
      };

    default:
      return state;
  }
};

export default dataReducer;
